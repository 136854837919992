<template>
  <v-dialog
    v-model="isShow"
    class="dialog-modal"
    @click:outside="$emit('close')"
    max-width="500px"
  >
    <button @click="closeButton(step)" class="modal-close-btn">
      <img src="@/assets/images/modal/close-circle.png" width="22px" />
    </button>
    <div class="verification-modal" v-if="step === 0">
      <img
        :src="require('@/assets/images/modal/security.png')"
        class="modal-image"
        width="50px"
      />
      <div class="modal-title">
        {{ $t("Validation.secVerification") }}
      </div>
      <div class="modal-description">
        {{ $t("Validation.secVerificationDesc") }}
      </div>
      <div class="modal-description">
        {{ $t("Validation.secVerificationDesc2") }}
      </div>

      <div
        class="verification-btn"
        :class="!validationStatus.totp_required ? 'selected' : ''"
        @click="step = 2"
        v-if="validationData.totp_enabled"
      >
        {{ $t("Validation.authApp") }}
        <img
          src="@/assets/images/wallet/round_check_icon.svg"
          width="24"
          v-if="!validationStatus.totp_required"
        />
        <v-icon v-else>mdi-chevron-right</v-icon>
      </div>
      <div
        class="verification-btn"
        :class="!validationStatus.otp_required ? 'selected' : ''"
        @click="
          step = 3;
          getEmailCode();
        "
      >
        {{ $t("Validation.emailVerification") }}
        <img
          src="@/assets/images/wallet/round_check_icon.svg"
          width="24"
          v-if="!validationStatus.otp_required"
        />
        <v-icon v-else>mdi-chevron-right</v-icon>
      </div>
    </div>

    <div class="modal-body-custom" v-if="step === 2">
      <img src="@/assets/images/auth/2FA-icon.png" class="modal-image" />

      <div class="modal-title">
        {{ $t("Validation.authAppVerification") }}
      </div>
      <div class="modal-description w-90 w-space-break">
        {{ $t("Validation.enter2FACodeDesc2") }}
      </div>

      <PincodeInput
        v-model="code"
        :errorMessage="codeError"
        :on-check="validateTotp()"
        @clearError="codeError = ''"
        :disabled="isLoading"
      />

      <button class="secondary-btn" @click="step = 4; isOtp = false" :disabled="isLoading">
        {{ $t("Login.trouble") }}
      </button>
    </div>

    <div class="modal-body-custom" v-if="step === 3">
      <img src="@/assets/images/auth/email-icon.png" class="modal-image" />

      <div class="modal-title">
        {{ $t("Validation.emailVerification") }}
      </div>

      <div
        class="modal-description w-space-break"
        v-html="$t('Validation.enter6digit', { email: isBusiness ? secureEmail : email })"
      ></div>

      <PincodeInput
        v-model="emailCode"
        :on-check="validateOtp()"
        :errorMessage="emailCodeError"
        @clearError="emailCodeError = ''"
        :disabled="isLoading"
      />
      <button
        class="main-btn"
        @click="getEmailCode"
        :disabled="emailDisabled || isSubmitLoading"
      >
        {{ $t("Profile.resendViaEmail") }}
        <span v-if="emailDisabled"> ({{ emailTimerCount }}{{ $t('Verification.second') }}) </span>
      </button>
      <button
        class="secondary-btn"
        @click="
          step = 4;
          isOtp = true;
        "
        :disabled="isLoading"
      >
        {{ $t("Login.trouble") }}
      </button>
    </div>

    <!-- HAVING TROUBLE -->
    <div class="modal-body-custom" v-if="step === 4">
      <div class="modal-title">{{ $t("TwoFA.havingTrouble") }}</div>

      <span
        v-for="(item, index) in isOtp ? otpList : totpList"
        :key="index"
        class="w-100"
      >
        <a
          class="having-trouble-item"
          :href="item.link"
          target="_blank"
          v-if="item.isNewTab"
        >
          <img
            :src="require(`@/assets/images/${item.img}`)"
            width="40px"
            v-if="item.img"
          />
          <div>{{ item.title }}</div>
        </a>

        <button
          class="having-trouble-item"
          @click="navigateToRoute(item.link)"
          v-else
        >
          <img
            :src="require(`@/assets/images/${item.img}`)"
            width="40px"
            v-if="item.img"
          />
          <div>{{ item.title }}</div>
        </button>
      </span>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: { type: Boolean, default: false },
    validationData: { type: Object },
    sendInfo: { type: Object },
  },
  data() {
    return {
      step: 0,
      code: "",
      codeError: "",
      emailCode: "",
      emailCodeError: "",
      emailDisabled: false,
      isSubmitLoading: false,
      email: this.$store.state.user.email,
      emailTimerCount: 0,
      methods: [
        {
          title: this.$t("Validation.authApp"),
          value: 2,
        },
        {
          title: this.$t("Validation.emailVerification"),
          value: 3,
        },
      ],
      isLoading: false,
      validationStatus: "",
      havingTrouble: false,
      totpList: [
        {
          img: "auth/lock-icon.png",
          title: this.$t("TwoFA.unableAccess"),
          link: "https://support.hata.io/support/tickets/new",
          isNewTab: true,
        },
        {
          img: "auth/2FA-not-working.png",
          title: this.$t("TwoFA.codeNotWorking"),
          link: "https://support.hata.io/support/solutions/articles/150000107432-how-to-solve-2fa-code-error",
          isNewTab: true,
        },
      ],
      otpList: [
        {
          img: "profile/gradient-email.png",
          title: this.$t("Validation.noReceiveOTP"),
          link: "https://support.hata.io/support/tickets/new",
          isNewTab: true,
        },
        {
          img: "profile/gradient-password.png",
          title: this.$t("TwoFA.codeNotWorking"),
          link: "https://support.hata.io/support/solutions/articles/150000107672-why-can-t-i-receive-emails-from-hata",
          isNewTab: true,
        },
      ],
      isOtp: true
    };
  },
  watch: {
    step: {
      handler() {
        if (this.step == 0) {
          this.requestValidation();
        }
      },
    },

    isShow: {
      handler() {
        if (this.isShow) {
          this.requestValidation();
        }
      },
    },
  },
  computed: {
    isShow() {
      return this.dialog;
    },

    isBusiness(){
      return this.$store.state.user.business
    },

    secureEmail() {
      return this.$store.state.user.secure_email;
    }
  },
  methods: {
    closeButton(value) {
      switch (value) {
        case 0:
          this.$emit("close");
          break;
        default:
          this.step = 0;
      }
    },

    async getEmailCode() {
      if (!this.emailDisabled) {
        this.isFail = false;
        this.emailDisabled = true;

        let params = {
          email: this.$store.state.user.email,
          amount: this.sendInfo.amount,
          symbol: this.sendInfo.coin,
          toAddress: this.sendInfo.toAddress,
          accountNumber: this.sendInfo.accountNumber,
          bankName: this.sendInfo.bankName,
          action: this.sendInfo.action,
          platform: this.$store.state.preferences.platform == "1" ? "MY" : "WW",
          request_id: this.sendInfo.request_id,
        };

        this.$store
          .dispatch("user/requestEmailOtp", params)
          .then(() => {
            this.countDownEmailTimer();
          })
          .catch((error) => {
            this.isFail = true;
            this.failTitle = this.$t("Modal.failToObtainEmailCode");
            this.errorMessage = this.errorCheck(error);
            this.emailDisabled = false;
            this.isShow = true;
          });
      }
    },

    countDownEmailTimer() {
      this.emailTimerCount = 60;
      let countdown = setInterval(() => {
        this.emailTimerCount -= 1;
      }, 1000);

      setTimeout(() => {
        clearInterval(countdown);
        this.emailDisabled = false;
      }, 60000);
    },

    async validateOtp() {
      if (this.emailCode && this.emailCode.length == 6) {
        this.isLoading = true;
        let params = {
          request_id: this.validationData.request_id,
          email_code: this.emailCode,
        };

        await this.$store
          .dispatch("auth/validateOtp", params)
          .then(() => {
            this.emailCode = "";
            this.step = 0;
          })
          .catch((error) => {
            this.emailCodeError = this.errorCheck(error);
            this.emailCode = "";
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },

    async validateTotp() {
      if (this.code && this.code.length == 6) {
        this.isLoading = true;
        let params = {
          request_id: this.validationData.request_id,
          token: this.code,
        };

        await this.$store
          .dispatch("auth/validateTotp", params)
          .then(() => {
            this.code = "";
            this.step = 0;
          })
          .catch((error) => {
            this.codeError = this.errorCheck(error);
            this.code = "";
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },

    async requestValidation() {
      let params = {
        request_id: this.validationData.request_id,
      };

      await this.$store
        .dispatch("auth/requestValidation", params)
        .then((data) => {
          this.validationStatus = data;
          const falseCount = Object.values(this.validationStatus).filter(
            (value) => !value
          ).length;

          if (falseCount >= 2) {
            this.$emit("complete");
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.verification-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  text-align: center;
  padding: 0 1rem 1rem 1rem;
}

.modal-close-btn {
  place-self: end;
  cursor: pointer;
  position: relative;
}

.verification-btn {
  width: 100%;
  border-radius: 6px;
  text-align: left;
  margin-top: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 18px 20px;
  border: 1px solid var(--gray50);
  color: var(--gray);

  .v-icon {
    margin-left: auto;
  }

  img {
    margin-left: auto;
  }

  &:hover {
    background-color: var(--hover-color);
  }

  &.selected {
    color: var(--header-text);
    background: var(--secondary20);
    border: 1px solid var(--secondary-color);
    pointer-events: none;
  }
}
</style>

<template>
    <span>
        <v-menu
            v-model="menu"
            location="bottom"
            open-on-hover
            :open-delay="0"
            :close-delay="0"
            :close-on-content-click="true"
            transition="scale-transition"
            v-if="isPlatform"
        >
            <template v-slot:activator="{ props }">
                <div
                    v-bind="props"
                    :class="
                        currentRoute && currentRoute.includes('GlobalView') ? 
                        'active-header-title': 
                        'header-title'
                    " 
                    @click="dropdownAction('GlobalView'); navigateToRoute('GlobalView');"
                >
                    <span v-if="owned_platform == 0">{{ $t("Header.Home") }}</span>
                    <div class="header-w-icon" v-else>
                        <img :src="require(`@/assets/images/header/sidebar/${selected_platform == 1 ? 'myr' : 'usd'}.svg`)" width="20px" />
                        <div>{{ selected_platform == 1 ? $t("Header.Malaysia") : $t("Header.Global") }}</div>
                    </div>

                    <span v-if="owned_platform == 3">
                        <v-icon :class="{ 'rotate-icon': menu, 'rotate-icon-reverse': !menu }">mdi-chevron-down</v-icon>
                    </span>
                </div>
            </template>

            <div class="custom-header-dropdown-options" v-show="owned_platform == 3 && menu">
                <button
                    v-for="(item, index) in options"
                    :key="index"
                    class="dropdown-inner-div header-w-icon"
                    :class="selected_platform == item.value ? 'primary-color-text' : 'opacity50'"
                    @click="switchPlatform(item.value)"
                >
                    <img :src="require(`@/assets/images/${item.icon}`)" v-if="item.icon"/>
                    {{ item.title }}
                </button>
            </div>
        </v-menu>
        <v-menu
            v-model="menu"
            location="bottom"
            open-on-hover
            :open-delay="0"
            :close-delay="0"
            :close-on-content-click="false"
            transition="scale-transition"
            v-else
        >
            <template v-slot:activator="{ props }">
                <div
                    v-bind="props"
                    class="header-web-profile"
                    :class="currentRoute && currentRoute.includes('ExchangeView') ? 'themed' : ''"
                    v-if="isProfile"
                >
                    <!-- <img src="@/assets/images/header/profile-icon.png" width="30px" /> -->
                    <InlineSvg 
                        :src="require(`@/assets/images/header/profile-icon.svg`)"
                        width="25px"
                    />
                </div>

                <div
                    v-bind="props"
                    :class="
                        checkRoute() ? 
                        'active-header-title': 
                        'header-title'
                    " 
                    v-else
                >
                    {{ title }}
                    <v-icon :class="{ 'rotate-icon': menu, 'rotate-icon-reverse': !menu }">mdi-chevron-down</v-icon>
                </div>
            </template>

            <div v-if="isProfile" class="custom-header-dropdown-options" v-show="menu">
                <div
                    v-for="(item, index) in options"
                    :key="index"
                    class="dropdown-inner-div header-w-icon profile-dropdown"
                    :class="currentRoute && currentRoute.includes(item.value) ? 'primary-color-text active-profile' : ''"
                >
                <router-link :to="generateRouteLink(item.value)">
                    <InlineSvg :src="require(`@/assets/images/header/${item.icon}`)" width="25px" />
                    {{ item.title }}
                </router-link>
                </div>

                <div class="dropdown-inner-div header-w-icon profile-dropdown" @click="$store.dispatch('auth/logout'); menu = false;">
                    <a>
                    <InlineSvg :src="require('@/assets/images/header/dropdown-logout.svg')" width="25px" />
                    {{ $t("Profile.logout") }}
                </a>
                </div>
            </div>

            <div v-else-if="isLanguage" class="custom-header-dropdown-options" v-show="menu">
                <div
                    v-for="(item, index) in options"
                    :key="index"
                    class="dropdown-inner-div header-w-icon"
                    :class="$i18n.locale.includes(item.value) ? 'primary-color-text active' : 'grey80-text inactive'"
                    @click.prevent="changeLanguage(item.value)"
                >
                {{ item.title }}
                </div>
            </div>

            <div v-else class="custom-header-dropdown-options" v-show="menu">
                <div
                    v-for="(item, index) in options"
                    :key="index"
                    class="dropdown-inner-div header-w-icon"
                    :class="currentRoute && currentRoute.includes(item.value) ? 'primary-color-text active' : 'grey80-text inactive'"
                >
                <router-link :to="generateRouteLink(item.value)">
                    <InlineSvg 
                        :src="require(`@/assets/images/header/sidebar/${item.icon}`)"
                        width="25px"
                    />
                    {{ item.title }}
                </router-link>
                </div>
            </div>
        </v-menu>
    </span>
</template>

<script>
export default {
    props: {
        title: { type: String, default: "" },
        options: { type: Array, default: () => [] },    
        isProfile: { type: Boolean, default: false },
        isPlatform: { type: Boolean, default: false },
        isLanguage: { type: Boolean, default: false }
    },

    data() {
        return {
            menu: false,
            selectedOption: ""
        };
    },

    computed: {
        owned_platform() {
            return this.$store.state.user.platform;
        },

        selected_platform() {
            return this.$store.state.preferences.platform;
        },

        currentRoute() {
            return this.$route.name;
        }
    },

    methods: {
        dropdownAction(option) {
            this.$emit("action", option);
            this.menu = false;
        },

        checkRoute() {
            if(this.currentRoute) {
                return this.options.some(i => this.currentRoute.includes(i.value));
            }

            return false;
        },

        switchPlatform(value) {
            this.$store.dispatch("preferences/setPlatform", value);
            this.navigateToRoute("GlobalView");
        },

        changeLanguage(value){
            if(this.$store.state.auth.isLoggedIn){
                this.$store.dispatch("user/setLanguage", {locale: value}).then(() => {
                this.$router.go();
            });
            }else{
                localStorage.setItem('lang', value);
                this.$router.go()
            }
        }
    }
}
</script>

<style>
.header-web-profile {
    padding: 0px 25px;
    cursor: pointer;
}

.header-web-profile:hover{
    opacity: 0.85;
}

.dark-theme .header-web-profile.themed path {
    fill: var(--primary-color);
}

.dark-theme .header-web-profile.themed rect {
    fill:var(--primary20);
}

.custom-header-dropdown-options {
    padding: 1rem;
    color: var(--header-text);
    background-color: var(--white);
    box-shadow: 20px 20px 70px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    min-width: 180px;
    border-radius: 6px;
}

.dropdown-inner-div a {
    color: var(--header-text) !important;
}

.dropdown-inner-div {
    padding: 12px 5px;
    cursor: pointer;
    border-bottom: 1px solid var(--gray40);
    display: flex;
    flex-direction: row;
    font-family: "Avenir";
}

.dropdown-inner-div:hover, .dropdown-inner-div:hover path{
    color: var(--primary-color);
    fill: var(--primary-color)
 }

 .profile-dropdown:hover rect{
    fill: var(--primary-color) !important;
 }

 .profile-dropdown:first-child:hover path{
    fill: var(--white) !important;
 }

.header-title .v-icon{
    color: var(--primary20)
}

.active-header-title .v-icon {
    color: var(--white) !important;
}

.header-title:hover .v-icon {
    color: var(--white) !important;
}

.header-w-icon {
    display: flex;
    align-items: center;
}

.header-w-icon img,
.header-w-icon svg {
    margin-right: 8px;
    margin-bottom: 3px;
}

.active path{
    fill: var(--primary-color)
}

.active-profile:first-child rect{
    fill: var(--primary-color)
}

.active-profile:not(:first-child) path{
    fill: var(--primary-color)
}

.inactive path {
    fill: var(--gray90)
}

/* .dropdown-inner-div:disabled:hover {
    opacity: 0.5;
} */
</style>
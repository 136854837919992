import api from "@/assets/api/api.js";
import * as global from "@/globalFunction.js";
let methods = global.default.methods;

const state = {
  tokens: [],
  selected_address: {
    addressId: "",
    address: "",
    symbol: "",
    network: "",
    tag: "",
    label: "",
  },
  receive_crypto: {
    symbol: "",
    network_name: "",
    is_tag: false,
    is_utxo: false,
  },
  crossPlatformList: [],
};

const mutations = {
  SET_WALLET_TOKENS(state, tokens) {
    state.tokens = tokens;
  },

  SET_SELECTED_ADDRESS(state, data) {
    state.selected_address = {
      addressId: data.id,
      address: data.address,
      symbol: data.symbol,
      network: data.network_name,
      tag: data.tag,
      label: data.label,
    };
  },

  SET_RECEIVE_CRYPTO(state, data) {
    state.receive_crypto = {
      symbol: data.symbol,
      network_name: data.network_name,
      is_tag: data.is_tag,
      is_utxo: data.is_utxo,
    };
  },

  SET_CROSSPLATFORM_TOKEN(state, data) {
    state.crossPlatformList = data;
  },
};

const actions = {
  async getTokens({ commit }) {
    let response = await api.apiCall("GET", "/wallet/api/token");

    if (response.status === "success") {
      let data = response.data;

      commit("SET_WALLET_TOKENS", data);
      return data;
    } else {
      console.error(response.message);
    }
  },

  async getSingleToken({ dispatch }, symbol) {
    if (state.tokens.length === 0) {
      await dispatch("getTokens");
    }

    let data = state.tokens.filter((i) => {
      return i.symbol === symbol;
    });

    return data[0];
  },

  async getWalletBalance(context, { isSorted = false, isWallet = false }) {
    let response = await api.apiCall("GET", "/orderbook/jwt/balance");

    if (response.status === "success") {
      let data = response.data != null ? response.data : [];
      let fiat = data.filter((i) => i.is_fiat == true);
      let crypto = data.filter((i) => i.is_fiat == false);
      crypto = crypto.sort((a, b) => {
        return a.symbol.localeCompare(b.symbol);
      });
      let totalUsdVal = data
        .map((item) => {
          if (item.is_fiat) {
            return methods.addDecimals(item.available, item.frozen);
          } else {
            return methods.addDecimals(
              item.available_in_quote,
              item.frozen_in_quote
            );
          }
        })
        .reduce((accumulator, currentValue) => methods.addDecimals(accumulator, currentValue), 0);

      if (isSorted) {
        crypto = sortCryptoList(crypto, isWallet);
      }

      return {
        data: response.data,
        fiat: fiat,
        crypto: crypto,
        totalUsdVal: totalUsdVal,
      };
    } else {
      console.error(response.message);
    }
  },

  async getSingleCoinBal(context, symbol) {
    let response = await api.apiCall("GET", "/orderbook/jwt/balance");

    if (response.status === "success") {
      let data = response.data != null ? response.data : [];
      data = data.filter((i) => i.symbol === symbol);
      return data[0];
    } else {
      console.error(response.message);
    }
  },

  async getCoinList() {
    let response = await api.apiCall("GET", "/wallet/api/cryptolist");

    if (response.status === "success") {
      return response.data != null ? response.data : [];
    } else {
      console.error(response.message);
    }
  },

  async getCoinNetwork(context, params) {
    let response = await api.apiCall("GET", "/wallet/api/cryptos", params);

    if (response.status === "success") {
      return response.data != null ? response.data : [];
    } else {
      console.error(response.message);
    }
  },

  // RECEIVE CRYPTO
  setReceiveCrypto({ commit }, data) {
    commit("SET_RECEIVE_CRYPTO", data);
  },

  async getCryptoDepDetail(context, params) {
    let response = await api.apiCall(
      "GET",
      "/wallet/jwt/address/deposit",
      params
    );

    if (response.status === "success") {
      return {
        data: response.data,
        is_exist: response.is_exist,
      };
    } else {
      throw response;
    }
  },

  async generateCryptoDepAddress(context, params) {
    let response = await api.apiCall(
      "POST",
      "/wallet/jwt/address/create",
      params
    );

    if (response.status !== "success") {
      throw response;
    }
  },

  // SEND CRYPTO (WITHDRAWAL)
  setSelectedAddress({ commit }, data) {
    commit("SET_SELECTED_ADDRESS", data);
  },

  async getSendCryptoDetails(context, params) {
    let response = await api.apiCall(
      "GET",
      "/wallet/jwt/withdrawal/details",
      params
    );

    if (response.status === "success") {
      return response.data;
    } else {
      console.error(response.message);
    }
  },

  async sendCrypto(context, params) {
    let response = await api.apiCall(
      "POST",
      "/wallet/jwt/withdrawal/create",
      params
    );

    if (response.status !== "success") {
      throw response;
    }
  },

  async requestSendExternalCrypto(context, params) {
    let response = await api.apiCall(
      "POST",
      "/wallet/jwt/v2/withdrawal/save-data",
      params
    );
    
    if (response.status !== "success") {
      throw response;
    } else {
      return response.data;
    }
  },

  async sendExternalCrypto(context, params) {
    let response = await api.apiCall(
      "POST",
      "/wallet/jwt/v2/withdrawal/create",
      params
    );

    if (response.status !== "success") {
      throw response;
    } else {
      return response
    }
  },
  
  async requestInternalTransfer(context, params){
    let response = await api.apiCall(
      "POST",
      "/wallet/jwt/withdrawal/internal/save-data",
      params
    );

    if(response.status !== "success"){
      throw response
    } else {
      return response.data
    }
  },

  async internalTransfer(context, params) {
    let response = await api.apiCall(
      "POST",
      "/wallet/jwt/withdrawal/internal",
      params
    );

    if (response.status !== "success") {
      throw response;
    } else {
      return response
    }
  },

  async crossPlatformTransfer(context, params) {
    let response = await api.apiCall(
      "POST",
      "/wallet/jwt/withdrawal/self/internal",
      params
    );

    if (response.status !== "success") {
      throw response;
    } else {
      return response;
    }
  },

  // ADDRESS BOOK
  async getAddressBook(context, params) {
    let response = await api.apiCall(
      "GET",
      "/wallet/jwt/address-book/list",
      params
    );

    if (response.status === "success") {
      return response.data != null ? response.data : [];
    } else {
      console.error(response.message);
    }
  },

  async requestCreateAddressBook(context, params){
    let response = await api.apiCall(
      "POST",
      "/wallet/jwt/address-book/save-data",
      params
    );

    if (response.status === "success") {
      return response.data;
    } else {
      throw response;
    }
  },

  async createAddressBook(context, params) {
    let response = await api.apiCall(
      "POST",
      "/wallet/jwt/address-book/create",
      params
    );

    if (response.status !== "success") {
      throw response;
    }
  },

  async deleteAddress(context, params) {
    let response = await api.apiCall(
      "DELETE",
      "/wallet/jwt/address-book/delete",
      params
    );

    if (response.status !== "success") {
      throw response;
    }
  },

  // CRYPTO HISTORY
  async getCoinHistory(context, params) {
    let response = await api.apiCall("GET", "/wallet/jwt/history", params);

    if (response.status === "success") {
      return {
        history: response.data.history !== null ? response.data.history : [],
        page: response.data.pages,
      };
    } else {
      console.error(response.message);
    }
  },

  async getCryptoWithdrawalHistory(context, params) {
    let response = await api.apiCall(
      "GET",
      "/wallet/jwt/withdrawal/history",
      params
    );

    if (response.status === "success") {
      return {
        history: response.data.history != null ? response.data.history : [],
        pages: response.data.pages,
      };
    }
  },

  async getCryptoDepHistory(context, params) {
    let response = await api.apiCall(
      "GET",
      "/wallet/jwt/deposit/history",
      params
    );

    if (response.status === "success") {
      return {
        history: response.data.history != null ? response.data.history : [],
        pages: response.data.pages,
      };
    }
  },

  async getOtcTradeHistory(context, params) {
    let response = await api.apiCall(
      "GET",
      "/otc/jwt/otc_transaction_log/history",
      params
    );

    if (response.status === "success") {
      return {
        history: response.data.orders != null ? response.data.orders : [],
        pages: response.data.pages,
      };
    }
  },

  //GET CROSSPLATFORM AVAILABLE TOKEN
  async getCrossPlatformToken({ commit }) {
    let response = await api.apiCall("GET", "/wallet/jwt/token/cross-platform");

    if (response.status === "success") {
      commit("SET_CROSSPLATFORM_TOKEN", response.data);
    }
  },

  crossPlatformAvailable({ state }, symbol) {
    let crossPlatformAvailable = false;
    if (symbol) {
      crossPlatformAvailable = state.crossPlatformList.find(
        (token) => token.symbol == symbol
      );
    }
    return crossPlatformAvailable ? true : false;
  },

  // REMAINING LIMIT
  async getRemainingLimit(context) {
    let response = await api.apiCall(
      "GET",
      "/wallet/jwt/withdrawal/remaining-limit"
    );

    if (response.status === "success") {
      return response.data;
    } else {
      console.error(response.message);
    }
  },
  async getUserRewardsHistory(context, params) {
    let response = await api.apiCall(
      "GET",
      "/promotion/jwt/rewards/paginate",
      params
    );

    if (response.status === "success") {
      return {
        rewards: response.data.rewards != null ? response.data.rewards : [],
        pages: response.data.pages,
      };
    }
  },
};

function sortCryptoList(data, isWallet = false) {
  if (!isWallet) {
    let balances = data.filter((i) => {
      return Number(i.available) > 0;
    });
    let zeroBalances = data.filter((i) => {
      return Number(i.available) == 0;
    });

    balances.sort((a, b) => {
      return Number(b.available_in_quote) - Number(a.available_in_quote);
    });
    return balances.concat(zeroBalances);
  } else {
    let balances = data.filter((i) => {
      return Number(i.available) + Number(i.frozen) > 0;
    });
    let zeroBalances = data.filter((i) => {
      return Number(i.available) + Number(i.frozen) == 0;
    });

    balances.sort((a, b) => {
      return (
        Number(b.available_in_quote) +
        Number(b.frozen_in_quote) -
        Number(a.available_in_quote) -
        Number(a.frozen_in_quote)
      );
    });
    return balances.concat(zeroBalances);
  }
}

export const wallet = {
  namespaced: true,
  state,
  mutations,
  actions,
};

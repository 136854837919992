<template>
    <v-navigation-drawer
        v-model="$store.state.preferences.showSideBar"
        disable-route-watcher
        disable-resize-watcher
        location="right"
        id="sidebar-1"
        :temporary="true"
        @click="$store.dispatch('preferences/setOpenSidebar', false)"
        v-if="!this.$route.query.id"
    >
        <div 
            class="drawer-header"
            :class="!isLogin ? 'justify-content-end' : ''"
        >
            <button 
                class="drawer-notification"
                @click="navigateToRoute('NotificationView');"
                v-if="isLogin"
            >
                <img src="@/assets/images/header/sidebar/bell.svg" />
                <div class="drawer-noti-dot" v-if="notification > 0">
                    {{ notification }}
                </div>
            </button>

            <button @click="$store.dispatch('preferences/setOpenSidebar', false)">
                <img src="@/assets/images/header/sidebarIcon.svg" />
            </button>
        </div>

        <div class="m-sidebar-body" v-if="!isLogin">
            <SidebarItem 
                v-for="item, index in nonLoginList"
                :key="index"
                :title="item.title"
                :data="item.data"
                :language="item.language"
            />

            <router-link 
                class="m-sidebar-button"
                :class="$route.name == 'SignInView' ? 'm-sidebar-active-btn' : ''"
                to="/login"
            >
                {{ $t("Header.SignIn") }}
            </router-link>
            <router-link  
                class="m-sidebar-button"
                :class="$route.name == 'SignUpView' ? 'm-sidebar-active-btn' : ''"
                to="/signup"
            >
                {{ $t("Header.SignUp") }}
            </router-link>
        </div>

        <div class="m-sidebar-body" v-else-if="$store.state.preferences.platform != 2">
            <SidebarItem 
                v-for="item, index in isLoginListMY"
                :key="index"
                :title="item.title"
                :data="item.data"
                :isHome="item.isHome"
            />

            <button 
                class="m-sidebar-button"
                @click="$store.dispatch('auth/logout')"
            >
                {{ $t("Profile.logout") }}
            </button>
        </div>

        <div class="m-sidebar-body" v-else>
            <SidebarItem 
                v-for="item, index in isLoginListGB"
                :key="index"
                :title="item.title"
                :data="item.data"
                :isHome="item.isHome"
            />

            <button 
                class="m-sidebar-button"
                @click="$store.dispatch('auth/logout')"
            >
                {{ $t("Profile.logout") }}
            </button>
        </div>
    </v-navigation-drawer>
</template>

<script>
import SidebarItem from "./SidebarItem.vue";

export default {
    components: {
        SidebarItem,
    },

    computed: {
        isLogin() {
            return this.$store.state.auth.isLoggedIn;
        },

        notification() {
            return this.$store.state.user.notification_count;
        }
    },

    data() {
        return {
            nonLoginList: [
                { 
                    title: this.$t("Header.Market"),  
                    data: [
                        { icon: "buy_crypto.svg", title: this.$t("wallet.instantBuySell"), link: "MarketView" },
                        { icon: "exchange.svg", title: this.$t("Header.Exchange"), link: "ExchangeView" },
                        { icon: "otc.svg", title: this.$t("Header.OTC"), link: "OtcView" }
                    ]
                }, 
                {
                    title: this.$t("Footer.Article"),
                    data: [
                        { icon: "explore.svg", title: this.$t("Header.Explore"), link: "https://explore.hata.io/", isNewTab: true },
                        { icon: "career.svg", title: this.$t("Footer.Careers"), link: "https://explore.hata.io/careers", isNewTab: true }
                    ]
                },
                {
                    title: this.$t("Header.FAQ"),
                    data: [
                        { icon: "message-question.svg", title: this.$t("Footer.HelpCentre"), link: "https://support.hata.io/support/home", isNewTab: true }
                    ]
                },
                {
                    title: this.$t("Header.Language"),
                    data: [
                        { language: true },
                    ]
                },
            ],
            
            isLoginListGB: [
                {
                    title: this.$t("Header.Home"),
                    data: [
                        { icon: "usd.svg", title: "", link: "GlobalView", switchable: true},
                    ],
                    isHome: true
                },
                { 
                    title: this.$t("Header.Market"),  
                    data: [
                        { icon: "buy_crypto.svg", title: this.$t("wallet.instantBuySell"), link: "MarketView" },
                        { icon: "exchange.svg", title: this.$t("Header.Exchange"), link: "ExchangeView" },
                        { icon: "otc.svg", title: this.$t("Header.OTC"), link: "OtcView" }
                    ]
                }, 
                {
                    title: this.$t("Header.Asset"),
                    data: [
                        { icon: "wallet.svg", title: this.$t("Header.Wallet"), link: "Wallet" },
                    ]
                },
                {
                    title: this.$t("Header.Profile"),
                    data: [
                        { icon: "profile.svg", title: this.$t("Header.Profile"), link: "ProfileView" },
                        { icon: "settings.svg", title: this.$t("Profile.settings"), link: "SettingView" },
                        { 
                            icon: "security.svg",
                            title: this.$t("Profile.security"), 
                            link: "SecurityView", 
                            isKyc: true 
                        }
                    ]
                },
                {
                    title: this.$t("Footer.Article"),
                    data: [
                        { icon: "explore.svg", title: this.$t("Header.Explore"), link: "https://explore.hata.io/", isNewTab: true },
                        { icon: "career.svg", title: this.$t("Footer.Careers"), link: "https://explore.hata.io/careers", isNewTab: true }
                    ]
                },
                {
                    title: this.$t("Header.FAQ"),
                    data: [
                        { icon: "message-question.svg", title: this.$t("Footer.HelpCentre"), link: "https://support.hata.io/support/home", isNewTab: true }
                    ]
                },
                {
                    title: this.$t("Header.Language"),
                    data: [
                        { language: true },
                    ]
                },
            ],
            isLoginListMY: [
                {
                    title: this.$t("Header.Home"),
                    data: [
                        { icon: "usd.svg", title: "", link: "GlobalView", switchable: true},
                    ],
                    isHome: true
                },
                { 
                    title: this.$t("Header.Market"),  
                    data: [
                        { icon: "buy_crypto.svg", title: this.$t("wallet.instantBuySell"), link: "MarketView" },
                        { icon: "exchange.svg", title: this.$t("Header.Exchange"), link: "ExchangeView" },
                        // { icon: "otc.svg", title: this.$t("Header.OTC"), link: "OtcView" }
                    ]
                }, 
                {
                    title: this.$t("Header.Asset"),
                    data: [
                        { icon: "wallet.svg", title: this.$t("Header.Wallet"), link: "Wallet" },
                    ]
                },
                {
                    title: this.$t("Header.Profile"),
                    data: [
                        { icon: "profile.svg", title: this.$t("Header.Profile"), link: "ProfileView" },
                        { icon: "settings.svg", title: this.$t("Profile.settings"), link: "SettingView" },
                        { 
                            icon: "security.svg",
                            title: this.$t("Profile.security"), 
                            link: "SecurityView", 
                            isKyc: true 
                        }
                    ]
                },
                {
                    title: this.$t("Footer.Article"),
                    data: [
                        { icon: "explore.svg", title: this.$t("Header.Explore"), link: "https://explore.hata.io/", isNewTab: true },
                        { icon: "career.svg", title: this.$t("Footer.Careers"), link: "https://explore.hata.io/careers", isNewTab: true }
                    ]
                },
                {
                    title: this.$t("Header.FAQ"),
                    data: [
                        { icon: "message-question.svg", title: this.$t("Footer.HelpCentre"), link: "https://support.hata.io/support/home", isNewTab: true }
                    ]
                },
                {
                    title: this.$t("Header.Language"),
                    data: [
                        { language: true },
                    ]
                },

            ],
        }
    },

    methods: {
    }
}
</script>

<style>
.drawer-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 19px;
    width: 100%;
    height: 85px;
    position: absolute;
    top: 0;
    background-color: var(--primary-color);
    z-index: 2;
}

.drawer-notification {
    position: relative;
}

.drawer-noti-dot {
    background-color: #F94242;
    color: var(--white);
    position: absolute;
    border-radius: 9px;
    top: 6px;
    font-size: 10px;
    min-width: 15px;
    height: 15px;
    width: fit-content;
    right: -5px;
    padding: 0px 4px;
    text-align: center;
    line-height: normal;
}

.m-sidebar-body {
    display: flex;
    flex-direction: column;
    padding: 0px 19px;
    margin-top: 85px;
}

.m-sidebar-button {
    padding: 12px 10px;
    text-align: center;
    width: 100%;
    border: none;
    margin-top: 8px;
    border-radius: 6px;
}

.m-sidebar-button:first-of-type {
    color: var(--primary-color) !important;
    background-color: var(--white);
    margin-bottom: 1rem;
}

.m-sidebar-button:nth-of-type(2) {
    color: var(--white) !important;
    background-color: var(--primary-color);
}

.m-sidebar-active-btn {
    border: 1px solid var(--white);
    color: var(--white) !important;
}
/* 
@media screen and (max-height: 678px) {
    .m-sidebar-body.overheight {
        min-height: 145vh;
        min-height: 145svh;
        height: 100%;
    }
} 

@media screen and (max-height: 650px) {
    .m-sidebar-body.overheight {
        min-height: 130vh;
        min-height: 130svh;
        height: 100%;
    }
} */

@media screen and (max-width: 768px) {
    #sidebar-1 .v-navigation-drawer__content {
        height: calc(100svh - 85px);
    }
}
</style>